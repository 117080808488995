.multi-select-dropdown {
  & > button {
    border-radius: 16px;
    border: 1px solid var(--theme-radio-background);
    padding: 5px 12px;
  }

  &.active > button {
    background-color: var(--theme-radio-background);
    color: var(--theme-background-content);
  }

  &-trigger svg {
    margin-left: 10px;
  }

  &-content {
    border-radius: 4px;
    background: var(--theme-background-content);
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.16);
    padding: 6px 16px;
  }

  &-checkbox-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    cursor: default;
  }

  &-checkbox-item[data-highlighted] {
    outline: none;
  }

  &-checkbox-item button.uncheck {
    background-color: var(--theme-radio-active);
    border: 1px solid var(--theme-input-border);
  }

  &-checkbox-item button {
    background-color: var(--theme-radio-background);
    border: none;
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
      color: var(--theme-radio-active);
    }
  }
}
