.select-trigger {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400 !important;
}

.select-icon {
  color: var(--theme-input-border);
}

.select-content {
  overflow: hidden;
  background-color: var(--theme-background-content);
  border-radius: 6px;
  box-shadow: 0 10px 38px -10px rgb(22 23 24 / 35%), 0 10px 20px -15px rgb(22 23 24 / 20%);
}

.select-viewport {
  padding: 5px;
}

.select-item {
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--theme-primary);
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}

.select-item[data-disabled] {
  color: var(--theme-input-border);
  pointer-events: none;
}

.select-item[data-highlighted] {
  outline: none;
  background-color: var(--theme-select-mouseover);
}

.select-item-indicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.select-scroll-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  cursor: default;
}
