.error-content {
  align-self: center;
  text-align: center;
  padding: 4.5rem 6.5rem;

  .uid2-logo {
    display: inline-block;
  }
  .uid2-logo-darkmode {
    display: none;
  }
}

#root.darkmode .error-content {
  .uid2-logo {
    display: none;
  }

  .uid2-logo-darkmode {
    display: inline-block;
  }
}
