.self-reinvite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  row-gap: 30px;

  .self-reinvite-form {
    background: var(--theme-background-content);
    border: 1px solid var(--theme-border);
    box-shadow: 0px 4px 14px var(--theme-box-shadow);
    border-radius: 0.5rem;
    padding: 3rem 8rem 5rem 8rem;
    box-sizing: border-box;

    h1 {
      font-size: 1.5rem;
    }

    .input-wrapper {
      width: 25rem;
    }
  }
}
