.participants-search-bar {
  .search-bar-type-filter-title {
    margin-bottom: 10px;
  }

  .search-bar-input {
    color: var(--theme-button-text);
    font-weight: 400;
    font-size: 1.1875rem;
    line-height: 1.4375;
  }

  .search-bar-type-filter {
    padding: 20px 20px 15px;
    border-bottom: 1px solid var(--theme-table-border);
  }

  .search-bar-participants {
    padding: 20px;

    .tristate-checkbox {
      margin-right: 16px;
    }

    tr {
      background: none !important;
    }

    th,
    td {
      background: none;
      padding: 0 0 16px;
    }

    th {
      padding-top: 19px;
    }

    tr:last-of-type td {
      padding-bottom: 19px;
    }

    tr th:first-of-type,
    tbody tr td:first-of-type {
      padding-left: 20px;
    }
  }

  .select-all {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.0625;
  }
}
