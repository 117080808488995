.input-wrapper {
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  margin-top: 1rem;

  .input-container {
    height: 45px;
    box-sizing: border-box;
    border: 1px solid var(--theme-input-border);
    border-radius: 5px;
    padding: 0.5rem;
    margin-top: 0.5rem;
    background-color: black;
    color: var(--theme-secondary);
    background-color: var(--theme-background-dialog);
  }

  .input-container:disabled {
    color: var(--theme-disabled-input);
    background-color: var(--theme-disabled-input-background);
    border: 1px solid var(--theme-disabled-input-border);
  }

  .multi-line-input-container {
    height: 150px;
    box-sizing: border-box;
    border: 1px solid var(--theme-input-border);
    border-radius: 5px;
    padding: 0.5rem;
    margin-top: 0.5rem;
  }

  .input-label {
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 2rem;
  }
  .input-container.withError {
    border: 1px solid var(--theme-error);
  }
}

.option-label {
  line-height: 1rem;
}

span[role='alert'] {
  color: red;
}
