@import '../../../styles/themes';

.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog-close-container {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .dialog-close-icon {
    top: 47px;
    right: 47px;
    background: none;
    border: none;
  }

  .dialog-title {
    font-weight: 600;
    font-size: 1.5rem !important;
    margin-top: 0 !important;
  }

  .dialog-close-button {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .dialog-cancel-button {
    border: none;
    background: none;
    color: var(--theme-dialog-close-button);
  }

  .icon-button:focus {
    box-shadow: 0 0 0 2px var(--theme-box-shadow);
  }

  .dialog-footer-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    gap: 10px;
  }
}

.dialog-overlay {
  background-color: var(--theme-backdrop);
  position: fixed;
  inset: 0;
  animation: overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlay-show {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.dialog-content {
  width: 100%;
  box-sizing: border-box;
  max-width: 606px;
  background-color: var(--theme-background-dialog);
  border-radius: 5px;
  box-shadow: 0 4px 14px var(--theme-box-shadow);
  max-height: 85vh;
  padding: 47px;
  position: relative;
  animation: content-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
  height: auto;

  @keyframes content-show {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.fullScreen {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100%;
  height: 100% !important;
  box-sizing: border-box;
  padding: 4.375rem !important;
}

.fullScreen > .dialog-close-container {
  position: absolute;
  right: 71px;
  top: 71px;
}
