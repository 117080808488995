.participants-table-container {
  .participants-table {
    width: 100%;

    tr th {
      font-size: 0.875rem;
      line-height: 1.5;
    }
  }

  .participants-table-header {
    display: flex;
    justify-content: end;
    align-items: baseline;
    padding-bottom: 10px;

    &-right {
      display: flex;
      justify-content: right;
    }

    .participants-search-bar-container {
      display: flex;
      align-items: center;
      border-bottom: 2px solid var(--theme-action);
      padding: 6px 2px;
      ::placeholder {
        color: var(--theme-search-text);
        opacity: 1;
      }
    }

    .participants-search-bar-icon {
      color: var(--theme-search-text);
      height: 16px;
      margin-left: 8px;
    }

    .participants-search-bar {
      width: 100%;
      border: none;
      outline: none;
      color: var(--theme-search-text);
      font-weight: 400;
      font-size: 0.75rem;
      background: none;
    }
  }
}
