.participant-item-with-checkbox {
  button[role='checkbox'] {
    width: 21px;
    height: 21px;
  }

  .tristate-checkbox {
    margin-right: 16px;
  }
}

.participant-name-cell {
  display: flex;
  align-items: center;

  .checkbox-label {
    padding-right: 16px;
  }
}
