.radio-group-root {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;

  .radio-option {
    display: flex;
    align-items: center;

    .radio-group-item:has(> .radio-group-indicator) {
      background-color: var(--theme-radio-background);
    }

    .disabled {
      background-color: var(--theme-disabled-button);
      cursor: default;
    }

    .radio-group-item {
      width: 25px;
      height: 25px;
      margin-right: 11px;
      padding: 0;
      border-radius: 100%;
      border: 1px solid var(--theme-input-border);

      .radio-group-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: relative;
      }
      .radio-group-indicator::after {
        content: '';
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: white;
      }
    }
  }
}
