@import '~normalize.css/normalize.css';
@import './fonts';

/* Base page styling - just the plumbing. Application styling belongs in App.scss or a component scss file! */

body {
  margin: 0;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}
