.display-secret {
  display: flex;
  justify-content: space-between;
  background-color: var(--theme-display-box);
  padding: 10px 10px;
  border-radius: 10px;

  p {
    padding: 0;
    margin: 0px 8px;
    word-break: break-all;
  }
}
