.dashboard-cards-container {
  display: flex;
  gap: 25px;

  .dashboard-card {
    margin-top: 28px;

    & > div {
      flex-grow: 1;
      border: 1px solid var(--theme-border);
      border-radius: 8px;
      max-width: 487px;
      padding: 30px;
      box-sizing: border-box;
    }

    h2 {
      font-size: 1.25rem;
      margin: 0 0 8px;
    }
  }
}
