.card {
  background: var(--theme-background-content);
  border: 1px solid var(--theme-border);
  box-shadow: 0px 4px 14px var(--theme-box-shadow);
  border-radius: 0.5rem;
  padding: 3rem 5rem;
  max-width: 39rem;
  box-sizing: border-box;
}
.card-header {
  margin-bottom: 2rem;
}

/*phone*/
@media (max-width: 767px) {
  .card {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-top: 0;
    border: 0;
    box-shadow: 0 0;
  }
}
