.rotate-api-keys-card {
  .rotate-api-keys-text {
    margin-bottom: 20px;
  }

  .warning-icon {
    padding: 0;
    margin-bottom: 10px;
    color: var(--theme-warning);
    height: 25px;
  }
}
