.participant-switcher {
  color: var(--theme-secondary);
  .select-dropdown-content {
    width: 200px;
  }
  .select-dropdown {
    & > button {
      border: none;
      padding: 0 0 20px 0;
    }
  }

  .select-dropdown-checkbox-item {
    justify-content: flex-start;
    padding-left: 10px;
    height: 100%;
    min-height: 40px;
  }

  .select-dropdown-trigger {
    display: flex;
    align-items: center;
    text-align: left;
    width: 190px;
    justify-content: space-between;
  }

  .participant-name {
    padding: 0 0 14px 0;
    font-weight: 600;
  }
}
