button {
  border: 1.5px solid var(--theme-button-border);
  background: none;
  border-radius: 5px;
  font-weight: 600;
  padding: 10px 20px;
  outline: none;
  cursor: pointer;
  color: var(--theme-button-text);

  &.text-button {
    border: none;
    padding: 0;
    color: var(--theme-action);
    font-weight: bold;
  }
}

.primary-button {
  background-color: var(--theme-button);
  color: var(--theme-primary-button-text);
  border: none;
}

.primary-button:disabled {
  background-color: var(--theme-disabled-button);
  border-color: var(--theme-disabled-button);
  color: var(--theme-disabled-button-text);
  cursor: not-allowed;
}

.large-button {
  padding: 1rem 3.75rem;
}

.small-button {
  padding: 0.75rem;
  font-size: 0.75rem;
}

.transparent-button {
  border: none;
  background: none;
  color: var(--theme-transparent-button);
}

.transparent-button:disabled {
  color: var(--theme-disabled-icon);
}

.transparent-button.small-button {
  padding: 0;
}

.icon-button {
  font-family: inherit;
  border: none;
  font-size: 1.25rem;
  padding: 0 10px;
  svg {
    color: var(--theme-action);
  }
}

.icon-button:disabled {
  svg {
    color: var(--theme-disabled-icon);
  }
}
