.key-pair-dialog {
  .disabled-checkbox {
    display: flex;
    align-items: center;
    padding-top: 24px;
    .checkbox-label {
      padding-right: 25px;
    }
  }
}
