.form-footer {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.form-message-container {
  min-height: 2.375rem;
  margin-top: 1.25rem;
}

.form-error {
  color: red;
}
