.sharing-permissions-table {
  h2 {
    margin-top: 0;
  }

  .dialog-body-section {
    font-weight: 400;
  }

  .sharing-permissions-table-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .sharing-permissions-search-bar-container {
    width: 190px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--theme-action);
    padding: 6px 2px;
    justify-content: space-between;
    ::placeholder {
      color: var(--theme-search-text);
      opacity: 1;
    }
  }

  .sharing-permission-search-bar-icon {
    color: var(--theme-search-text);
    height: 16px;
    margin-left: 8px;
  }

  .sharing-permission-actions {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 11px;
  }

  .sharing-permissions-search-bar {
    width: 100%;
    border: none;
    outline: none;
    color: var(--theme-search-text);
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    background: none;
  }

  .shared-participants-table {
    width: 100%;
    tr th {
      background-color: var(--theme-background-sidepanel);
      font-size: 0.875rem;
      line-height: 1.5;
    }

    &.selected tr th {
      background-color: var(--theme-info);
    }

    th:first-of-type,
    td:first-of-type {
      padding-left: 10px;
      width: 21px;
    }
  }

  .no-participants-container {
    margin-top: 16px;
    border: 1px solid var(--theme-border);
    border-radius: 5px;
    padding: 20px 20px 36px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
  }

  .no-participants-text {
    margin-left: 40px;
  }

  .sharing-permission-delete-button {
    color: var(--theme-table-action-button);
    padding: 0;
  }

  .sharing-permission-trashcan-icon {
    margin-right: 8px;
  }
}
