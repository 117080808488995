table {
  border-collapse: collapse;

  thead {
    th:first-of-type {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    th:last-of-type {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  th {
    background-color: var(--theme-background-table-header);
    color: var(--theme-table-header-text);
  }
  th:first-of-type,
  td:first-of-type {
    padding-left: 40px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  th:last-of-type,
  td:last-of-type {
    padding-right: 40px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  th,
  td {
    padding-top: 10px;
    padding-bottom: 12px;
  }
  tbody {
    tr:nth-of-type(2n) {
      background-color: var(--theme-background-sidepanel);
    }
  }

  th.action {
    text-align: end;
    padding-left: 50px;
  }

  .action {
    padding-right: 30px;
  }

  .action-cell {
    font-size: 0.8125rem;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    .action-button {
      padding: 5px 5px 5px 10px;
    }

    svg {
      font-size: 1rem;
    }
  }
}

.table-actions {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 11px;
  margin-bottom: 10px;

  .table-action-button {
    color: var(--theme-table-action-button);
    padding: 0;
  }
}
