.portal-team {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .add-team-member {
    margin-top: 24px;
    margin-right: 30px;
  }

  .portal-team-table {
    .role-header {
      display: flex;
      gap: 10px;
    }

    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .action-cell {
      .invite-button {
        &.clickable {
          cursor: pointer;
          color: var(--theme-action);
        }

        &.error {
          color: var(--theme-error);
        }

        border: none;
        white-space: nowrap;
        align-items: center;
        padding: 0 10px;
      }
    }

    .name-cell {
      display: flex;
      align-items: center;
      .pending-label {
        margin-left: 10px;
        .label {
          background-color: var(--theme-label-pending-background);
        }
      }
    }
  }
}
