h1 {
  font-size: 1.875rem;
  margin-bottom: 5px;
  font-weight: 600;
  color: var(--theme-title);
  margin-top: 5px;
}

h2 {
  font-size: 1.125rem;
  line-height: 1.375;
  margin-top: 20px;
  font-weight: 600;
}

h3 {
  font-size: 0.8125rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

a {
  color: var(--theme-secondary);

  &.outside-link {
    text-decoration: none;
    color: var(--theme-action);
  }
}
