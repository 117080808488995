.participant-management-item {
  line-height: 2;

  .participant-item-logo {
    width: 50px;
    margin-right: 16px;
  }

  .participant-item-name-cell {
    display: flex;
    align-items: center;
  }

  .approve-button {
    padding-right: 0;
  }

  .approver-date {
    margin-right: 80px;
  }

  .approver-name {
    margin-right: 40px;
  }
}

.participants-request-dialog {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
}
