.document-card {
  display: flex;
  height: 300px;

  img {
    height: 49.5px;
    margin-right: 23.5px;
  }

  a:first-of-type {
    margin-top: 0.5rem;
  }

  a {
    display: block;
    color: var(--theme-documentation-card);
    font-size: 0.9375rem;
    line-height: 2;
    font-weight: 600;
    text-decoration: none;
  }
}
