@import '../../styles/themes';

.key-pairs {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 20px;

  .key-pairs-table {
    font-size: 0.875rem;

    thead {
      font-weight: 700;
      border: none;
    }

    th {
      padding-right: 10px;

      .action {
        text-align: end;
        padding-left: 140px;
      }
    }

    td {
      padding: 15px 5px 15px 0;
    }

    .action-cell {
      font-size: 0.8125rem;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      svg {
        font-size: 1rem;
      }
    }
  }

  .key-pair-header {
    display: flex;
    gap: 10px;

    .key-pair-tool-tip {
      margin-top: 0.4rem;
    }
  }

  .key-pairs-table-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 10px;

    &-right {
      display: flex;
      align-items: center;
    }
  }
}
