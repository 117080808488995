.dashboard-content {
  padding: 23px 48px;
  flex-grow: 1;
  background-color: var(--theme-background-content);
}

.dashboard-footer {
  margin-top: 3rem;
  border-top: 1px solid #dcdee1;
  padding-top: 1.375rem;
}
