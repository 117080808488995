.business-contacts-table-container {
  .add-new-item {
    margin-top: 24px;
  }

  .small-button {
    margin-top: 24px;
  }

  .business-contacts-table {
    .name {
      width: 25%;
    }

    .email {
      width: 25%;
    }

    .contactType {
      width: 25%;
    }

    font-size: 0.875rem;
    width: 100%;

    thead {
      font-weight: 700;
      border: none;
    }

    th.action {
      text-align: end;
      padding-left: 140px;
    }

    .action {
      padding-right: 80px;
    }

    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .action-cell {
      font-size: 0.8125rem;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      align-items: baseline;

      .icon-button {
        &.clickable {
          cursor: pointer;
          color: var(--theme-action);
        }

        &.error {
          color: var(--theme-error);
        }

        border: none;
        white-space: nowrap;
        align-items: center;
        padding: 0 10px;
      }

      svg {
        font-size: 1rem;
      }
    }
  }
}
