.side-nav {
  padding: 23px 12px 0;
  min-width: 250px;
  background-color: var(--theme-background-sidepanel);

  a {
    text-decoration: none;
    font-weight: 600;
  }

  .active {
    border-bottom: 3px solid var(--theme-button-border);
  }

  li {
    display: block;
    margin: 2px 0;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  .hidden {
    display: none;
  }

  .side-nav-item {
    padding: 5px 10px;
  }

  .main-nav {
    padding: 0 30px;

    .side-nav-item {
      padding: 12px 0;
    }

    .side-nav-divider {
      border-bottom: 1px solid var(--theme-footer-divider);
    }
  }

  .nav-footer {
    color: var(--theme-title);

    .side-nav-divider {
      border-bottom: 1px solid var(--theme-footer-divider);
      margin: 10px;
    }

    .side-nav-item,
    a {
      &.version-info {
        font-size: 0.625rem;
        color: var(--theme-primary);
      }

      font-size: 0.75rem;
      font-weight: 400;
      color: var(--theme-title);
    }

    .portal-documentation-link a {
      font-size: 0.875rem;
    }
    position: fixed;
    margin: 0 12px 12px 12px;
    bottom: 0;
    left: 0;
    min-width: 250px;
  }
}
