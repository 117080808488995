.tooltip {
  display: flex;
  align-self: center;

  button.tooltip-trigger {
    cursor: help;
    border: none;
    padding: 0;
    width: inherit;
    font-weight: 400;

    .default-trigger {
      color: var(--theme-info);
    }
  }

  .tooltip-content {
    color: var(--theme-button-text);
    border-radius: 4px;
    background-color: var(--theme-background-sidepanel);
    padding: 10px;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.125;
    max-width: 190px;
    box-shadow: 0px 4px 4px var(--theme-box-shadow);
  }

  .tooltip-arrow {
    fill: var(--theme-background-sidepanel);
  }
}
