.terms-container {
  margin-bottom: 22px;
  font-size: 0.8125rem;
  line-height: 1.8;

  div {
    margin: 13px 0;
  }

  h1 {
    text-align: center;
    margin-top: 0;
  }

  td {
    vertical-align: top;
  }

  .terms-bold {
    font-weight: 600;
  }
}

.terms-and-conditions-form {
  flex-direction: column;
  align-items: center;
  height: 100%;
  display: flex;

  & > h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    align-self: flex-start;
  }

  .text-button {
    margin-top: 10px;
  }

  .terms-container {
    border: 1px solid var(--theme-border);
    border-radius: 5px;
    padding: 16px;
    overflow: scroll;
    max-width: 910px;
  }
}
