@import '../../../styles/themes';

.popover-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .popover-content {
    width: 100%;
    box-sizing: border-box;
    max-width: 400px;
    background-color: var(--theme-background-content);
    border-radius: 5px;
    box-shadow: 0 4px 14px var(--theme-box-shadow);
    max-height: 85vh;
    padding: 16px;
    position: relative;
    animation: content-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
    height: auto;
    overflow-wrap: anywhere;

    @keyframes content-show {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
}
