.edit-participant-dialog {
  max-height: calc(100vh - 10px);

  .contact-name {
    display: flex;
    justify-content: space-between;

    .input-wrapper {
      width: 45%;
    }
  }
}
