@import './styles/colors';
@import './styles/themes';
@import './styles/toast.scss';

.app {
  @import 'styles/tables';
  @import 'styles/text';
  @import 'styles/buttons';

  text-align: left;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: var(--theme-primary);
  font-size: 0.875rem;

  .app-panel {
    display: flex;
    align-items: stretch;
    background-color: var(--theme-background-header);
    flex-grow: 1;

    .heading-details {
      margin: 4px 0;
    }

    p[role='alert'] {
      color: var(--theme-error);
    }
  }

  .app-centralize {
    align-items: center;
    justify-content: center;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: var(--theme-scrollbar) none;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--theme-scrollbar);
    border-radius: 20px;
    border: 3px solid var(--theme-scrollbar);
  }
}
