.add-participant-dialog {
  max-height: calc(100vh - 10px);
  max-width: 1100px;
  overflow-y: auto;
  padding: 3rem !important;

  h4 {
    font-size: 18px;
  }

  .add-participant-dialog-flex {
    display: flex;

    .right-column {
      margin-left: 64px;
    }
  }

  .text-input,
  .user-job-function,
  .site-type {
    width: 400px;
  }

  .highlight {
    background-color: var(--theme-search-highlight);
  }

  .search-bar {
    position: relative;
    width: 400px;

    .search-bar-input-container {
      margin-top: 0.5rem;
      padding: 11px;
      border-radius: 5px;
    }

    .search-input {
      color: var(--theme-secondary);
    }

    .site-search-results {
      position: absolute;
      padding: 10px;
      width: 378px;
      max-height: 350px;
      overflow-y: auto;

      & > button {
        padding: 10px;
        display: block;
        font-weight: normal;
        color: var(--theme-primary);
      }
    }
  }

  .action-container {
    .request-button,
    .cancel-button {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }
}
