.bulk-add-permissions {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  line-height: 1;

  .bulk-add-permissions-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 0 20px;
    flex-direction: row;

    .recommendation-label {
      background: var(--theme-label-background);
      border-radius: 3px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      height: 18px;
    }
  }

  .bulk-add-permissions-body {
    padding: 0 10px;

    .bulk-add-permissions-body-warnings {
      display: flex;
      flex-direction: column;

      & > div {
        margin-top: 20px;
      }
    }

    .participant-type-checkbox-section {
      display: flex;
      align-items: center;

      .checkbox-label {
        padding-right: 25px;
      }
    }

    .bulk-add-permissions-view-recommendations-button {
      padding-top: 20px;
    }

    .bulk-add-permissions-participants-table {
      display: flex;
      padding: 10px 0;
      margin-top: 20px;
      border: 1px solid var(--theme-border);
      border-radius: 5px;
      max-height: 410px;
      overflow: auto;

      td:first-of-type {
        padding-left: 32px;
      }
      tr {
        background-color: var(--theme-background-content);
      }
    }
  }

  .bulk-add-permissions-footer {
    padding-top: 20px;
  }
}

.bulk-add-permissions-recommendations-collapsible {
  border: 2px solid var(--theme-action) !important;
  box-shadow: 0px 4px 14px var(--theme-box-shadow);
}
