.select-dropdown {
  .select-dropdown-content {
    max-height: 400px;
    overflow: auto;
    border-radius: 5px;
    border: 1px solid var(--theme-border);
  }

  & > button {
    border: none;
    padding: 5px 12px;
  }

  &.active > button {
    background-color: var(--theme-radio-background);
    color: var(--theme-background-content);
  }

  &-trigger svg {
    margin-left: 10px;
  }

  &-content {
    border-radius: 4px;
    background: var(--theme-background-content);
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.16);
    width: 50px;
  }

  &-checkbox-item {
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: center;
    cursor: default;
    color: var(--theme-secondary);
  }

  &-checkbox-item:hover {
    background-color: var(--theme-border);
    color: black;
  }

  &-checkbox-item[data-highlighted] {
    outline: none;
  }

  &-checkbox-item .checked {
    font-weight: 800;
  }
}
