.display-secret-table {
  .view-button {
    color: var(--theme-action);
    padding: 0 8px;
    font-size: 1rem;
  }
  p {
    word-break: break-all;
  }
}
