.label {
  background: var(--theme-label-background);
  color: var(--theme-label-text);
  font-size: 0.75rem;
  line-height: 1.15;
  border-radius: 3px;
  padding: 4px 8px;
  margin-right: 8px;
  align-self: self-start;
}
