.sortable-table-header {
  border: none;
  svg {
    margin-left: 5px;
  }
  color: var(--theme-table-header-text);

  .sortable-table-header-button {
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    color: var(--theme-table-header-text);
  }
}
