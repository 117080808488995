.no-participant-access-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem 6.5rem;
  font-size: 1rem;
  background-color: var(--theme-background-content);
  height: 100vh;

  .instructions {
    font-size: 1.25rem;
    margin-top: 0;
    color: var(--theme-secondary);
  }

  .no-access-text {
    font-weight: bold;
    margin-bottom: 0.25rem;
  }

  .use-switcher-text {
    margin-bottom: 1rem;
  }

  .switcher {
    width: fit-content;
    border: 2px solid gray;
    padding-top: 1rem;
  }
}
